import classNames from "classnames";
import React, { ComponentProps } from "react";

import { fluidImage } from "../../css/styles/styles.css";
import { RasterImage } from "../rasterImage";
import { clippedImage, container } from "./clippedImage.styles.css";

type Props = ComponentProps<typeof RasterImage>;

export const ClippedImage = ({ className, ...props }: Props) => {
  const classnames = classNames(className, fluidImage, clippedImage);
  return (
    <div className={container}>
      <RasterImage {...props} className={classnames} />
    </div>
  );
};
