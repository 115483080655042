import { graphql, HeadProps, PageProps } from "gatsby";
import * as React from "react";
import PortableText from "react-portable-text";

import { ClippedImage } from "../components/clippedImage";
import { Hero } from "../components/hero";
import PageLayout from "../components/pageLayout";
import { PageSection } from "../components/pageSection";
import { RasterImage } from "../components/rasterImage";
import { fluidImage } from "../css/styles/styles.css";
import { usePanelImages } from "../hooks/usePanelImages";
import { HeadTemplate } from "../templates/headTemplate";
import { getPaletteFromImageData } from "../util/dynamicThemePalette";

type TemplateProps = PageProps<Queries.AboutJenQuery>;

const AboutPage: React.FC<TemplateProps> = ({ path, data }) => {
  const bio = data.sanityAboutJen?.bio as any;
  const headline = data.sanityAboutJen?.headline || "About Jen";
  const image = data.sanityAboutJen?.image;
  const heroImage = usePanelImages(1)[0];

  const dynamicPalette = getPaletteFromImageData(image, "lightVibrant");

  return (
    <PageLayout themeColor={dynamicPalette}>
      <Hero title={headline} bgImage={heroImage} />
      <PageSection variants={{ display: "flex", direction: "row" }}>
        <div>{image && <ClippedImage {...image} className={fluidImage} />}</div>
        {bio?.map && <PortableText content={bio} />}
      </PageSection>
    </PageLayout>
  );
};

export const query = graphql`
  query AboutJen {
    sanityAboutJen {
      headline
      bio {
        ...portableText
      }
      image {
        alt
        attribution
        ...imageWithPalette
      }
    }
  }
`;

export const Head: React.FC = () => HeadTemplate({ title: "About Jen" });

export default AboutPage;
